<template>
  <div :class="['project-categories', { loader: loader }]">
    <ui-loader v-model="loader" />
    <UiNotification v-model="showNotification" :message="message" />
    <Table
        v-if="!loader"
        :title="$t('project-categories')"
        :actionText="$t('add-category')"
        showAction
        trash-is-hidden
        :data="getEsgCategories.data"
        :descriptionList="descriptionList"
        hideEditIcon
        @changeRowField="changeRowField($event)"
        @addRowField="addRowField($event)"
        @deleteRowField="deleteRowField($event)"
        :sort="true"
        :isGlobeIcon="true"
    />
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import UiLoader from "@/components/ui/UiLoader";
import UiNotification from "@/components/ui/UiNotification";

export default {
  name: "IncidentTypes",
  components: {
    UiNotification,
    UiLoader,
    Table: () => import("@/components/ui/UiTable"),
  },

  data() {
    return {
      descriptionList: [
        {title: this.$t('title'), width: 440}
      ],
      loader: true,
      showNotification: false,
      message: ''
    }
  },
  methods: {
    ...mapActions([
      'esgCategories',
      'changeEsgCategory',
      'addEsgCategory',
      'deleteEsgCategory'
    ]),
    changeRowField(data) {
      this.loader = true;

      this.changeEsgCategory({
        name: data.name,
        id: data.id,
        ord: +data.ord,
        nameInt: {
          en: data.nameIntEn
        }
      }).then(() => {
        this.esgCategories()
        setTimeout(() => {
          this.loader = false
          this.message = this.$t('category-successfully-updated')
          this.showNotification = true
        }, 500)
      })
    },

    addRowField(data) {
      this.loader = true;

      this.addEsgCategory({
        name: data.name,
        ord: +data.ord
      }).then(() => {
        this.esgCategories()
        setTimeout(() => {
          this.loader = false
          this.showNotification = true
          this.message = this.$t('category-successfully-added')
        }, 500)
      })
    },

    deleteRowField (data) {
      this.deleteEsgCategory({
        id: data.id
      }).then(() => {
        this.esgCategories()
        setTimeout(() => {
          this.loader = false
          this.showNotification = true
          this.message = this.$t('category-successfully-deleted')
        }, 500)
      })
    }
  },

  computed: {
    ...mapGetters(['getEsgCategories'])
  },

  mounted() {
    this.esgCategories().then(() => {
      this.loader = false
      console.log(this.getEsgCategories)
    })
  }
}
</script>

<style lang="scss" scoped>
.project-categories {
  width: 100%;
  max-width: 1000px;

  &.loader {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>